<template>
  <div class="animated">
    <div id="snackbar"></div>

    <!-- modal ends -->

    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Vouchers
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          :theme="theme"
          id="dataTable"
        >
          <div slot="voucher_type" slot-scope="props">
            {{ props.row.voucher_type == 1 ? "Movie" : "Event" }}
          </div>
          <div slot="ID" slot-scope="props">
            <i class="fa fa-edit" @click="editMovie(props.row.voucher_id)"></i>
          </div>

          <div slot="MovieIsActive" slot-scope="props">
            <c-switch
              class="mx-1"
              color="primary"
              v-model="props.row.MovieIsActive"
              variant="3d"
              label
              v-bind="labelIcon"
              @change.native="changeStatus(props.row.MovieID)"
            />
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";

import { Switch as cSwitch } from "@coreui/vue";
import _ from "lodash";

export default {
  name: "AllMovies",
  components: {
    cSwitch,
  },
  data: function () {
    return {
      columns: ["voucher_code", "voucher_type", "ID"],
      columns1: ["CriticReview", "Title", "CriticRating", "ID"],
      data: [],
      data1: [],
      options: {
        headings: {
          Title: "Voucher",
          Type: "Voucher Type",
          // Genre: "Genre",
          // Released: "Released",
          // MovieIsActive: "Status",
          ID: "Edit",
        },
        sortable: ["Title"],
        filterable: ["Title"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      options1: {
        headings: {
          CriticReview: "Critic Review",
          // Runtime: "Runtime",
          // Genre: "Genre",
          // Released: "Released",
          Title: "Title",
          CriticRating: "Critic Ratings",
          ID: "Edit",
        },
        sortable: ["Title"],
        filterable: ["Title"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  mounted() {},
  beforeMount() {
    this.getVoucher();
  },
  methods: {
    getVoucher() {
      // let res = await MovieService.getMovies();
      // this.data = res.data;

      let payload = {
        voucher_id: "NA",
      };
      this.data = [];
      MasterService.getAllVouchers(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.data.length > 0) {
            this.data = data.data;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editMovie: function (VCHID) {
      this.$router.push({ path: "/movies/addvoucher", query: { ID: VCHID } });
    },
    onEditCriticRating(ReviewID, MovieID) {
      console.log("ReviewID,MovieID :", ReviewID, MovieID);
      this.$router.push({
        path: "/movies/add-critic-rating",
        query: { ReviewID, MovieID },
      });
    },
    changeStatus: function (ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "Movies");
      let payload = {
        TableName: "Movies",
        ID,
      };
      let index = _.findIndex(this.data, { MovieID: ID });
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.data[index].MovieIsActive = MovieIsActive;
          } else {
            this.data[index].MovieIsActive = !MovieIsActive;
          }
        })
        .catch((error) => {
          console.log("Catch on DashUsers-changeStatus Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
